.login-page1 {
	background: linear-gradient(
		to right top,
		#b6dafb,
		#8eb3df,
		#6a8dc3,
		#4b67a5,
		#314387,
		#233477,
		#142667,
		#011957,
		#001c52,
		#001d4c,
		#001e46,
		#001f3f
	);
	padding-top: 7%;
}

.login-box {
	width: 400px;
	box-shadow: 5px 5px 40px #999 inset;
	padding: 20px 10px;
	border-radius: 0.5rem;
}

.login-logo {
	text-align: center;
	background-color: transparent;
	padding: 5px;
}

.login-logo-img-center {
	position: relative;
	background: transparent;
	left: 50%;
	transform: translate(-50%);
	width: 150px;
	height: 150px;
	border-radius: 50%;
	box-shadow: 1px 1px 20px #999 inset;
	border: solid 0.25px #666;
}

.login-logo-img {
	position: relative;
	background: transparent;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	box-shadow: 1px 1px 20px #999 inset;
	border: solid 0.25px #666;
}

.login-logo-img img,
.login-logo-img-center img {
	position: relative;
	width: 100% !important;
	background: transparent;
}

.login-logo a {
	color: #495057;
}

.login-box-title {
	margin-top: 20px;
	text-align: center;
}

.login-box .card {
	color: #666;
	padding: 10px;
	background: transparent;
}

.login-card-body {
	border-top: 0;
	color: #666;
	padding: 20px;
	background: transparent;
}

.login-card-body .input-group .input-group-append {
	background: #fff;
	border-radius: 0px 5px 5px 0px;
}

.login-card-body .input-group .form-control:focus {
	box-shadow: none;
	border-color: #999;
}

.login-card-body
	.input-group
	.form-control:focus
	~ .input-group-append
	.input-group-text {
	border-color: #999;
}

.login-box-msg {
	margin: 0;
	padding: 0 20px 20px;
	text-align: center;
	font-size: 1.2rem;
}

.login-card-body button[type='submit'] {
	background: #c7c9cb;
}

.login-box .login-footer {
	text-align: center;
	background-color: #fff;
	font-size: 9px;
	color: #999;
	bottom: 0;
	padding: 5px;
	position: static;
}

.daftar-page {
	-ms-flex-align: center;
	align-items: center;
	background: #003;
	display: -ms-flexbox;
	display: flex;
	height: 100vh;
	-ms-flex-pack: center;
	justify-content: center;
	padding-top: 5%;
}

.daftar-box {
	width: 40%;
	background: #fff;
	padding: 10px;
	border-radius: 5px;
}

.daftar-logo {
	text-align: center;
	background-color: transparent;
	padding: 5px;
}

.daftar-logo img {
	width: 40%;
}

.daftar-logo a {
	color: #495057;
}

.daftar-box-title {
	margin-top: 20px;
	text-align: center;
}

.daftar-box .card {
	color: #666;
	padding: 10px;
}

.daftar-card-body {
	background: #fff;
	border-top: 0;
	color: #666;
	padding: 20px;
}

.daftar-box-msg {
	margin: 0;
	padding: 0 20px 20px;
	text-align: center;
}

.daftar-box .daftar-footer {
	text-align: center;
	background-color: transparent;
	font-size: 9px;
	color: #999;
}

.daftar-card-body .input-group .form-control {
	border-right: 0;
}

.daftar-card-body .input-group .form-control:focus {
	box-shadow: none;
}

.daftar-card-body
	.input-group
	.form-control:focus
	~ .input-group-append
	.input-group-text {
	border-color: #80bdff;
}

.daftar-card-body .input-group .form-control.is-valid:focus {
	box-shadow: none;
}

.daftar-card-body
	.input-group
	.form-control.is-valid
	~ .input-group-append
	.input-group-text {
	border-color: #28a745;
}

.daftar-card-body .input-group .form-control.is-invalid:focus {
	box-shadow: none;
}

.daftar-card-body
	.input-group
	.form-control.is-invalid
	~ .input-group-append
	.input-group-text {
	border-color: #dc3545;
}

.daftar-card-body .input-group .input-group-text {
	background-color: transparent;
	border-bottom-right-radius: 0.25rem;
	border-left: 0;
	border-top-right-radius: 0.25rem;
	color: #777;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (max-width: 1600px) {
	.daftar-box {
		width: 60%;
	}
}

@media (max-width: 1200px) {
	.daftar-box {
		width: 80%;
	}

	.daftar-logo img {
		width: 50%;
	}
}

@media (max-width: 400px) {
	.daftar-box,
	.login-box {
		width: 95%;
	}

	.daftar-logo img {
		width: 60%;
	}
}

.form-error {
	width: 100%;
	font-size: 0.8rem;
	font-weight: 400;
	line-height: 1;
	color: red;
}

.loader-spin {
	border: 5px solid #66ccff;
	border-top: 5px solid navy;
	border-bottom: 5px solid navy;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	left: 50%;
	top: 50%;
	position: absolute;
	animation: spin 2s linear infinite;
	-webkit-animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.fas,
.fa {
	width: 15px;
}

/* rotari border */
.border-rotari {
	position: relative;
	z-index: 0;
	overflow: hidden;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}

.border-rotari::before {
	content: '';
	position: absolute;
	z-index: -2;
	left: -10%;
	top: -10px;
	width: 120%;
	height: 120%;
	background-repeat: no-repeat;
	background-size: 40% 30%, 40% 30%, 40% 40%, 40% 40%, 0px 0px;
	background-position: 0 0, 100% 0, 0 100%, 100% 100%;
	background-image: linear-gradient(#ffc107, #ffc107),
		linear-gradient(#ffc107, #ffc107), linear-gradient(#ffc107, #ffc107),
		linear-gradient(#ffc107, #ffc107);

	animation: rotate 4s linear infinite;
}

.border-rotari::after {
	content: '';
	position: absolute;
	z-index: -1;
	left: 3px;
	top: 3px;
	bottom: 0px;
	width: calc(100% - 7px);
	height: calc(100% - 7px);
	background: linear-gradient(
		to right top,

		#314387,
		#233477,
		#142667,
		#011957,
		#001c52,
		#001d4c,
		#001e46,
		#001f3f
	);
}
