.layout-navbar-fixed .main-header-landing {
	position: relative;
	height: 100vh;
}

.layout-navbar-fixed .main-header-landing .card-img {
	height: 100%;
	position: absolute;
}

.layout-navbar-fixed .main-header-landing .card-img-overlay {
	background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.9));
	text-align: center;
	position: relative;
	height: 100vh;
	padding-top: 15%;
}

.layout-navbar-fixed .main-header-landing .card-img-overlay .logo-text {
	background: transparent;
	text-align: center;
	word-wrap: normal;
	box-shadow: none;
}

.layout-navbar-fixed .main-header-landing .logo-text .card-title {
	font-size: calc(1rem + 3vw);
	text-transform: uppercase;
	font-weight: bold;
	padding-bottom: 10px;
}

.layout-navbar-fixed .main-header-landing .logo-text .card-text {
	font-size: 1.2vw;
}

.jenis-dtks {
	margin-top: -20vh;
	margin-bottom: 20px;
	min-height: 300px;
	padding: 20px;
	background: #fff;
	position: relative;
	width: 95%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	border-radius: 0.5rem;
	/* box-shadow: 1px 1px 20px #999 inset; */
}

.logo-psksk-center {
	position: absolute;
	top: -120px;
	background: transparent;
	left: 50%;
	transform: translate(-50%);
	width: 220px;
	height: 220px;
	border-radius: 50%;
	box-shadow: 1px 1px 20px #999 inset;
	border: solid 0.5px #666;
}

.logo-psksk-center img {
	position: relative;
	width: 100% !important;
	background: transparent;
}

.title {
	font-size: calc(1rem + 1vw);
}

@media screen and (max-width: 991.98px) {
	.layout-navbar-fixed .main-header-landing .logo-text .card-title {
		font-size: 2em;
	}

	.layout-navbar-fixed .main-header-landing .logo-text .card-text {
		font-size: 0.9em;
	}

	.layout-navbar-fixed .main-header-landing .card-img-overlay {
		padding-top: 250px;
	}

	.jenis-dtks .logo-psksk-center {
		width: 190px;
		height: 190px;
	}
}

@media screen and (max-width: 767.98px) {
	.layout-navbar-fixed .main-header-landing .card-img-overlay {
		text-align: center;
	}

	.layout-navbar-fixed .main-header-landing .card-img-overlay {
		padding-top: 120px;
	}
}

.section-gray {
	background: #f8f9fa;
	padding: 5px;
}

.section-white {
	padding: 5px;
	background: #fff;
}

.card-no-shadow {
	box-shadow: none;
}

.card-shadow-inset-gray {
	box-shadow: 1px 1px 20px #999 inset;
}

.form-control-no-size {
	resize: none;
}

.form-gray .form-border-gray:focus {
	border-bottom: 0.1rem solid #999 !important;
}

.captcha {
	letter-spacing: 0.5em;
	font-family: Copperplate, Papyrus, fantasy;
	font-size: 20px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
	height: calc(3rem + 2px);
	text-align: center;
	padding-top: 15px;
	bottom: 15px;
}

.card-body .direct-chat-messages.chat-messages-pesan {
	min-height: 700px !important;
	overflow-y: auto;
}

.front-end .main-sidebar {
	background: #f4f6f9;
	z-index: 1;
}

.front-end #sidebar-overlay {
	position: relative;
}

.front-end .wrapper {
	background: #f4f6f9;
}

.front-end .nav-link-data-widget {
	display: none !important;
}

.front-end.sidebar-collapse [data-widget='pushmenu'],
.front-end.sidebar-open [data-widget='pushmenu'] {
	display: block !important;
}

.front-end .main-header [data-widget='pushmenu'] .fa-times {
	display: none;
}

.front-end.sidebar-open .main-header [data-widget='pushmenu'] .fa-times {
	display: block;
}


.front-end.sidebar-open .main-header {
	display: flex !important;
	-ms-flex-direction: column !important;
	flex-direction: column !important;
}

@media screen and (min-width: 992px) {
	.front-end .wrapper .content-wrapper {
		margin-left: 250px;
	}
}

@media screen and (max-width: 768px) {
	.front-end .wrapper .content-wrapper,
	.front-end .wrapper .main-sidebar {
		padding-top: 50px;
	}

	.w-sm-100 {
		width: 100%;
	}
}

@media screen and (max-width: 575.98px) {
	.front-end .wrapper .content-wrapper {
		padding-top: 50px;
	}

	.front-end .wrapper .main-sidebar {
		padding-top: 50px;
	}
}

@media screen and (max-width: 424px) {
	.jenis-dtks .logo-psksk-center {
		top: -100px;
	}

	.jenis-dtks .logo-psksk-center {
		width: 150px;
		height: 150px;
	}
}

.sidebar .nav-treeview .nav-link {
	font-size: 14px;
}

.main-header ul > li a {
	font-size: calc(0.125rem + 12px);
}

.icon-cicle {
	border-radius: 50%;
	border: none;
}

.foto-tksk {
	width: 150px;
	height: 150px;
	border-radius: 50%;
}

.img-rotari {
	animation: img-rotari 1.5s infinite;
}

@keyframes img-rotari {
	0% {
		transform: rotate(0);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}

	50% {
		transform: rotate(900deg);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	100% {
		transform: rotate(1800deg);
	}
}

.logo-text-hover:hover .card-title,
.logo-text-hover:hover .card-text {
	transition: 1s;
	opacity: 0;
}

.logo-text .card-title,
.logo-text .card-text {
	transition: 1s;
}

.logo-kecil {
	position: relative;
	width: 180px !important;
	height: 180px !important;
	background: transparent;
	padding: 5px;
}

.logo-kecil-bulat {
	position: relative;
	width: 150px !important;
	height: 150px !important;
	background: transparent;
	margin: 10px;
	border-radius: 50%;
	padding: 10px;
}

.navbar-toggler {
	border: none !important;
	margin-top: 5px;
}

.container-win {
	width: 100%;
	padding-right: 7.5px;
	padding-left: 7.5px;
	margin-right: auto;
	margin-left: auto;
}

.navbar .container-win {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

@media screen and (min-width: 1280px) {
	.container-win {
		max-width: 1278px;
	}
}

.main-footer {
	height: 50px;
	margin-bottom: 0px;
	position: relative;
}

.box-sidenav25 {
	height: 0%;
	width: 0px;
	position: fixed;
	z-index: 1040;
	right: -20px;
	margin-top: 0px;
	visibility: hidden;
	background-color: rgba(0, 0, 0, 0.4);
	
}

.box-sidenav25.sidenav-open {
	width: 100%;
	height:  100%;
	visibility: visible;
	transition: 0.2s;
	overflow-x: hidden;
	overflow-y: auto;
	right: 0;
	top:0;
}

.box-sidenav25.sidenav-open .box-sidenav-content {
	width: 400px;
	position : relative;
	margin-left: auto;
	margin-right: auto;
	top: 25%;
}

.box-sidenav50 {
	height: 0%;
	width: 0px;
	position: fixed;
	z-index: 1040;
	right: -20px;
	padding: 3px;
	margin-top: 0px;
	visibility: hidden;
	background-color: rgba(0, 0, 0, 0.4);
}

.box-sidenav50.sidenav-open {
	width: 100%;
	height: 100%;
	visibility: visible;
	transition: 0.2s;
	overflow-x: hidden;
	overflow-y: auto;
	right: 0;
	top: 0;
	vertical-align: middle;
}

.box-sidenav50.sidenav-open .box-sidenav-content {
	width: 50%;
	height: 100%;
	padding: 20px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 5%;
}

.box-sidenav60 {
	height: 0%;
	width: 0px;
	position: fixed;
	z-index: 1040;
	right: -20px;
	bottom: 0;
	top: 0px;
	padding: 10px;
	margin-top: 0px;
	visibility: hidden;
	background-color: rgba(0, 0, 0, 0.4);
}

.box-sidenav60.sidenav-open {
	width: 100%;
	height: 100%;
	visibility: visible;
	transition: 0.2s;
	right: 0px;
	overflow-x: hidden;
	overflow-y: auto;
}

.box-sidenav60.sidenav-open .box-sidenav-content {
	width: 60%;
	height: 100%;
	font-size: 11px;
	padding: 0px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 5%;
}

@media (max-width: 1400px) {
	
	.box-sidenav60.sidenav-open .box-sidenav-content {
		width: 90%;
	}
}

@media (max-width: 1200px) {
	
	.box-sidenav50.sidenav-open .box-sidenav-content {
		width: 70%;
	}
	.box-sidenav60.sidenav-open .box-sidenav-content {
		width: 95%;
	}
}

@media (max-width: 767px) {
	.box-sidenav25.sidenav-open .box-sidenav-content
	 {
		top: 25%;
	}
	.box-sidenav50.sidenav-open .box-sidenav-content {
		width: 100%;
		margin-top: 25%;
	}
	.box-sidenav60.sidenav-open .box-sidenav-content {
		width: 100%;
	}
}


@media (max-width: 420px) {
	.box-sidenav25.sidenav-open .box-sidenav-content
	 {
		width: 95%;
		top: 25%;
	}
}

footer .copyright {
	display: table;
	padding: 20px 20px;
	align-items: center;
	height: 50px;
	font-size: 0.8em;
	float: right;
}


.dropdown-item.active,
.dropdown-item:active {
	background-color: #001f3f;
}

.pas-foto-img {
	position: relative;
	background: transparent;
	left: 50%;
	transform: translate(-50%);
	width: 150px;
	height: 150px;
	border-radius: 50%;
	box-shadow: 1px 1px 20px #999 inset;
	border: solid 0.5px #666;
	padding: 10px;
	overflow: hidden;
}

.pas-foto-img img {
	position: relative;
	width: 100% !important;
	height: 100%;
	background: transparent;
	border-radius: 50%;
}


.form-gray .form-control:not(.form-control-border):focus,.form-control:not(.form-control-border):focus {
	border: 0.1rem solid #999 !important;
}

.form-gray .form-control.form-control-border:focus {
	border-bottom: 0.1rem solid #999 !important;
}



.form-gray .date {
	width: 100%;
	height: calc(2.25rem + 2px);
	line-height: 1.5;
	background-color: #fff;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	box-shadow: inset 0 0 0 transparent;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-gray .date .form-control:focus {
	border: none !important;
	background: transparent;
}

.form-gray .date .form-control,
.form-gray .date .input-group-append {
	border: none !important;
	height: 100%;
}

.form-gray .date .input-group-text {
	background: transparent;
	border: none !important;
}

.table > th,
.table > tr,
.table > td {
	position: relative;
}

.card-loader-spin {
	border: 5px solid #66ccff;
	border-top: 5px solid navy;
	border-bottom: 5px solid navy;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	position: relative;
}

.loader-spin {
	border: 5px solid #66ccff;
	border-top: 5px solid navy;
	border-bottom: 5px solid navy;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	left: 50%;
	top: 50%;
	position: absolute;
	animation: spin 2s linear infinite;
	-webkit-animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loader {
	position: fixed;
	display: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9998;
	cursor: pointer;
	background-color: #000033;
	opacity: 0.6;
	filter: alpha(opacity=60);
}

.gototop {
	display: none;
	position: fixed;
	bottom: 2px;
	right: 5px;
	width: 40px;
	height: 40px;
	z-index: 99999;
	background: #ff0000;
	background: url(../images/gototop.png) no-repeat transparent;
}

/* datatable */

.dataTables_paginate .pagination > li.active,
.dataTables_paginate .pagination > li.active > a,
.dataTables_paginate .pagination > li.active > span {
	background: #dee2e6 !important;
	border-color: #ccc !important;
	color: #000 !important;
	box-shadow: none;
}


.btn.btn-fab.btn-sm {
	height: 30px;
	min-width: 30px;
	width: 30px;
}

.colored-toast.swal2-icon-success {
	background-color: #28a745 !important;
}

.colored-toast.swal2-icon-error {
	background-color: #dc3545 !important;
}

.colored-toast.swal2-icon-warning {
	background-color: #e6af0c !important;
}

.colored-toast.swal2-icon-info {
	background-color: #3fc3ee !important;
}

.colored-toast.swal2-icon-question {
	background-color: #87adbd !important;
}

.colored-toast .swal2-title {
	color: white;
}

.colored-toast .swal2-close {
	color: white;
}

.colored-toast .swal2-content {
	color: white;
}

footer .copyright {
	display: table;
	padding: 20px 20px;
	align-items: center;
	height: 50px;
	font-size: 0.8em;
	float: right;
}

.brand-link .brand-image {
	float: none;
}

.front-end.sidebar-open .main-header .logo-nav {
	width: 100%;
}

.front-end.sidebar-open .main-header .logo-nav img {
	display: none;
}

@media (min-width: 991.98px) {
	.front-end.sidebar-collapse .main-header .logo-nav img {
		display: block;
	}

	.front-end .main-header .logo-nav img {
		display: none;
	}
}

@media (max-width: 991.98px) {
	.front-end .main-header {
		display: flex !important;
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}

	.front-end .main-header .logo-nav {
		width: 100% !important;
	}
}

.navbar-nav .user-image {
	float: left;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	margin-right: 10px;
	margin-top: -2px;
}

.control-sidebar {
	top: 0;
	right: -230px;
	width: 230px;
	-webkit-transition: right 0.3s ease-in-out;
	-o-transition: right 0.3s ease-in-out;
	transition: right 0.3s ease-in-out;
	max-height: 200px !important;
	border-bottom: 1px solid #d2d6de;
	border: none;
}
.control-sidebar-heading {
	font-weight: 400;
	font-size: 16px;
	padding: 10px 0;
	margin-bottom: 10px;
}

.control-sidebar-subheading {
	display: block;
	font-weight: 400;
	font-size: 14px;
}

.control-sidebar-menu {
	list-style: none;
	padding: 0;
	background: #222;
}

.control-sidebar-menu > li > a {
	display: block;
	padding: 10px 15px;
	color: white;
}

.control-sidebar-menu > li > a:before,
.control-sidebar-menu > li > a:after {
	content: ' ';
	display: table;
}

.control-sidebar-menu > li > a:after {
	clear: both;
}

.control-sidebar-menu > li > a > .control-sidebar-subheading {
	margin-top: 0;
}

.control-sidebar-menu .menu-icon {
	float: left;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	text-align: center;
	line-height: 35px;
}

.control-sidebar-menu .menu-info {
	margin-left: 45px;
	margin-top: 3px;
}

.control-sidebar-menu .menu-info > .control-sidebar-subheading {
	margin: 0;
}

.control-sidebar-menu .menu-info > p {
	margin: 0;
	font-size: 11px;
}

.control-sidebar-menu .progress {
	margin: 0;
}
.control-sidebar .control-sidebar-menu > li > a:hover {
	background-color: #666;
}


.form-control-no-size {
	resize: none;
}

.colored-toast.swal2-icon-success {
	background-color: #28a745 !important;
}

.colored-toast.swal2-icon-error {
	background-color: #dc3545 !important;
}

.colored-toast.swal2-icon-warning {
	background-color: #e6af0c !important;
}

.colored-toast.swal2-icon-info {
	background-color: #3fc3ee !important;
}

.colored-toast.swal2-icon-question {
	background-color: #87adbd !important;
}

.colored-toast .swal2-title {
	color: white;
}

.colored-toast .swal2-close {
	color: white;
}

.colored-toast .swal2-content {
	color: white;
}

.chart-h50 {
	height: 50vh;
}

.chart-h55 {
	height: 55vh;
}

.chart-h60 {
	height: 60vh;
}

.fas,
.far,
.fab,
.fa {
	min-width: 15px;
}

.bg-gray-light {
	background: #a9a9a9;
}

.input-group.icon .form-control,
.input-group.icon .form-control:focus {
	border-right: none !important;
}
.input-group.icon .form-control ~ .input-group-append {
	border: 1px solid #ced4da;
	background: #fff;
	border-radius: 0px 5px 5px 0px;
	box-shadow: none;
	border-left: none;
}
.input-group.icon .form-control ~ .input-group-append .input-group-text {
	border: none;
	background: transparent;
	box-shadow: none;
}

.input-group.icon .form-control:focus ~ .input-group-append {
	border: 1px solid #999;
	background: #fff;
	border-left: none;
}

@media (min-width: 992px) {
	.col-lg {
		-webkit-flex-basis: 0;
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.row-cols-lg-1 > * {
		-webkit-flex: 0 0 100%;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.row-cols-lg-2 > * {
		-webkit-flex: 0 0 49.77777%;
		-ms-flex: 0 0 49.77777%;
		flex: 0 0 49.77777%;
		max-width: 49.77777%;
		margin-right: 1px;
		margin-left: 1px;
	}
	.row-cols-lg-3 > * {
		-webkit-flex: 0 0 33%;
		-ms-flex: 0 0 33%;
		flex: 0 0 33%;
		max-width: 33%;
		margin-right: 1px;
		margin-left: 1px;
	}
	.row-cols-lg-4 > * {
		-webkit-flex: 0 0 24.77777%;
		-ms-flex: 0 0 24.77777%;
		flex: 0 0 24.77777%;
		max-width: 24.77777%;
		margin-right: 1px;
		margin-left: 1px;
	}
	.row-cols-lg-5 > * {
		-webkit-flex: 0 0 19.77777%;
		-ms-flex: 0 0 19.77777%;
		flex: 0 0 19.77777%;
		max-width: 19.77777%;
		margin-right: 1px;
		margin-left: 1px;
	}
	.row-cols-lg-6 > * {
		-webkit-flex: 0 0 16.33333%;
		-ms-flex: 0 0 16.33333%;
		flex: 0 0 16.33333%;
		max-width: 16.33333%;
		margin-right: 1px;
		margin-left: 1px;
	}
}

@media (min-width: 768px) {
	.row-cols-md-2 > * {
		-webkit-flex: 0 0 49.77777%;
		-ms-flex: 0 0 49.77777%;
		flex: 0 0 49.77777%;
		max-width: 49.77777%;
		margin-right: 1px;
		margin-left: 1px;
	}
	.row-cols-md-3 > * {
		-webkit-flex: 0 0 33%;
		-ms-flex: 0 0 33%;
		flex: 0 0 33%;
		max-width: 33%;
		margin-right: 1px;
		margin-left: 1px;
	}
	.row-cols-md-4 > * {
		-webkit-flex: 0 0 24.77777%;
		-ms-flex: 0 0 24.77777%;
		flex: 0 0 24.77777%;
		max-width: 24.77777%;
		margin-right: 1px;
		margin-left: 1px;
	}
	.row-cols-md-5 > * {
		-webkit-flex: 0 0 19.77777%;
		-ms-flex: 0 0 19.77777%;
		flex: 0 0 19.77777%;
		max-width: 19.77777%;
		margin-right: 1px;
		margin-left: 1px;
	}
	.row-cols-md-6 > * {
		-webkit-flex: 0 0 16.33333%;
		-ms-flex: 0 0 16.33333%;
		flex: 0 0 16.33333%;
		max-width: 16.33333%;
		margin-right: 1px;
		margin-left: 1px;
	}
}

@media (min-width: 576px) {
	.row-cols-sm-2 > * {
		-webkit-flex: 0 0 49.77777%;
		-ms-flex: 0 0 49.77777%;
		flex: 0 0 49.77777%;
		max-width: 49.77777%;
		margin-right: 1px;
		margin-left: 1px;
	}
	.row-cols-sm-3 > * {
		-webkit-flex: 0 0 33%;
		-ms-flex: 0 0 33%;
		flex: 0 0 33%;
		max-width: 33%;
		margin-right: 1px;
		margin-left: 1px;
	}
	.row-cols-sm-4 > * {
		-webkit-flex: 0 0 24.77777%;
		-ms-flex: 0 0 24.77777%;
		flex: 0 0 24.77777%;
		max-width: 24.77777%;
		margin-right: 1px;
		margin-left: 1px;
	}
	.row-cols-sm-5 > * {
		-webkit-flex: 0 0 19.77777%;
		-ms-flex: 0 0 19.77777%;
		flex: 0 0 19.77777%;
		max-width: 19.77777%;
		margin-right: 1px;
		margin-left: 1px;
	}
	.row-cols-sm-6 > * {
		-webkit-flex: 0 0 16.33333%;
		-ms-flex: 0 0 16.33333%;
		flex: 0 0 16.33333%;
		max-width: 16.33333%;
		margin-right: 1px;
		margin-left: 1px;
	}
}
